import { captureException } from '@sentry/react';
import { EmptyStatePrimaryAction } from '@synoptic/ui-kit/empty-state/empty-state-primary-action.js';
import { EmptyStateSecondaryAction } from '@synoptic/ui-kit/empty-state/empty-state-secondary-action.js';
import { EmptyState } from '@synoptic/ui-kit/empty-state/empty-state.js';
import { EmojiSadIcon } from '@synoptic/ui-kit/icons/react/emoji-sad.js';
import { useRouteError } from 'react-router';
import { errorBlock } from './page.css';

export const ErrorBanner = ({
  withContactUs = false,
}: {
  withContactUs?: boolean;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const error = useRouteError() as any;

  if (error instanceof Error) {
    captureException(error);
  }

  return (
    <div>
      <EmptyState
        illustration={<EmojiSadIcon />}
        heading="Something went wrong"
        description={
          <div>
            Please reload the page. If it doesn’t help, send this text to us:
            <pre className={errorBlock}>
              {error?.statusText ||
                `${error?.name || 'Error'}: ${error?.message}`}
            </pre>
          </div>
        }
        action={
          <EmptyStatePrimaryAction
            onClick={() => {
              location.reload();
            }}
          >
            Reload
          </EmptyStatePrimaryAction>
        }
        secondaryAction={
          withContactUs ? (
            <EmptyStateSecondaryAction variant="secondary" asChild>
              <a
                href={`https://synoptic.fibery.io/@public/forms/YtvyxD6j`}
                target="_blank"
                rel="noreferrer"
              >
                Contact us
              </a>
            </EmptyStateSecondaryAction>
          ) : null
        }
      />
    </div>
  );
};
